import React from 'react';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionRulesMaybe.module.css';

const SectionRulesMaybe = props => {
  const { className, rootClassName, publicData } = props;
  const classes = classNames(rootClassName || css.root, className);

  const availableFrom = publicData.availableFrom?.date
    ? new Date(publicData.availableFrom.date).toLocaleDateString()
    : publicData.availableFrom
      ? new Date(publicData.availableFrom).toLocaleDateString()
      : null;

  const availableUntil = publicData.availableUntil?.date
    ? new Date(publicData.availableUntil.date).toLocaleDateString()
    : publicData.availableUntil
      ? new Date(publicData.availableUntil).toLocaleDateString()
      : null;

  const isOffering = publicData?.category === 'offeringPlace';
  const isLooking = publicData && publicData.category === 'lookingForPlace';
  const privatquartier = publicData && publicData.type === 'privatquartier';

  const language = publicData.language;
  const capacity = publicData.capacityNumberOfPeople;
  const size = publicData.size;
  const pets = publicData.pets && publicData.pets === 'yes';
  const petsNotAllowed = publicData.pets && publicData.pets === 'no';
  const petsInfo = publicData.petsInfo;
  const accessibility =
    Array.isArray(publicData.accessibility) && publicData.accessibility[0] === 'yes';
  const accessibilityInfo = publicData.accessibilityInfo;
  const noAccessibility =
    Array.isArray(publicData.accessibility) && publicData.accessibility[0] === 'no';

  const assistanceAnimals =
    Array.isArray(publicData.assistanceAnimals) && publicData.assistanceAnimals[0] === 'yes';
  const assistanceAnimalsNotAlloed =
    Array.isArray(publicData.assistanceAnimals) && publicData.assistanceAnimals[0] === 'no';
  const assistanceAnimalsInfo = publicData.assistanceAnimalsInfo;

  const allergies = publicData.allergies && publicData.allergies === 'yes';
  const noAllergies = publicData.allergies && publicData.allergies === 'no';
  const allergiesInfo = publicData.allergiesInfo;

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="ListingPage.rulesTitle" />
      </h2>
      <p className={css.info}>
        <strong>
          {isLooking ? "Suche ab:" : "Verfügbar ab:"}
        </strong>
        {availableFrom}
      </p>

      <p className={css.info}>
        <strong>
          {isLooking ? "Suche bis:" : "Verfügbar bis:"}
        </strong>
        {availableUntil}
      </p>

      {!privatquartier && size &&
        <p className={css.info}>
          <strong>{isLooking ? 'Mindestgröße:' : 'Stellplatzgröße:'}</strong>
          {size}
        </p>
      }

      {language &&
        <p className={css.info}>
          <strong>{isOffering ? 'Sprache(n) des Gastgebers:' : 'Sprache(n) des Gastes:'}</strong>
          {language}
        </p>
      }

      {privatquartier && capacity &&
        <p className={css.info}>
          <strong>
            {isLooking ? "Gästeanzahl:" : "Gästekapazität:"}
          </strong>
          {capacity}
        </p>
      }

      {privatquartier && accessibility && accessibilityInfo &&
        <p className={css.info}>
          <strong>Barrierefreiheit:</strong>
          {accessibilityInfo}
        </p>
      }

      {privatquartier && noAccessibility &&
        <p className={css.info}>
          <strong>Barrierefreiheit:</strong>
          Nicht benötigt
        </p>
      }

      {privatquartier && isLooking && assistanceAnimals && assistanceAnimalsInfo &&
        <p className={css.info}>
          <strong>
            Assistenztiere:
          </strong>
          {assistanceAnimalsInfo}
        </p>
      }

      {privatquartier && isLooking && assistanceAnimalsNotAlloed &&
        <p className={css.info}>
          <strong>
            Assistenztiere:
          </strong>
          Nicht vorhanden
        </p>
      }

      {privatquartier && isOffering && pets && petsInfo &&
        <p className={css.info}>
          <strong>Haustiere:</strong>
          {petsInfo}
        </p>
      }

      {privatquartier && isOffering && petsNotAllowed &&
        <p className={css.info}>
          <strong>Haustiere:</strong>
          Nicht vorhanden
        </p>
      }

      {privatquartier && isLooking && allergies && allergiesInfo &&
        <p className={css.info}>
          <strong>Allergien:</strong>
          {allergiesInfo}
        </p>
      }

      {privatquartier && isLooking && noAllergies &&
        <p className={css.info}>
          <strong>Allergien:</strong>
          Nicht vorhanden
        </p>
      }
    </div >
  );

  // return publicData && publicData.rules ? (
  //   <div className={classes}>
  //     <h2 className={css.title}>
  //       <FormattedMessage id="ListingPage.rulesTitle" />
  //     </h2>
  //     <p className={css.rules}>{publicData.rules}</p>
  //   </div>
  // ) : null;
};

SectionRulesMaybe.defaultProps = { className: null, rootClassName: null };

SectionRulesMaybe.propTypes = {
  className: string,
  rootClassName: string,
};

export default SectionRulesMaybe;
