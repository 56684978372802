import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  const { publicData } = props;

  if (!publicData.category && !publicData.type) {
    return null;
  }

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      {publicData.category &&
        <p><FormattedMessage id={`ListingPage.${publicData.category}`} /></p>
      }
      {publicData.type &&
        <p><FormattedMessage id={`ListingPage.${publicData.type}`} /></p>
      }

    </div>
  );
};

export default SectionFeaturesMaybe;
